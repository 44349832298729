<template>
  <div>
    <div
      class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-white">User Notifications</span>
        <!-- <span
          class="
            btn btn-text btn-success btn-sm
            font-weight-bold
            btn-font-md
            ml-2
          "
        >
          {{ data.length +  messages.length }} new
        </span> -->
      </h4>

      <ul
        class="
          nav
          nav-bold
          nav-tabs
          nav-tabs-line
          nav-tabs-line-3x
          nav-tabs-line-transparent-white
          nav-tabs-line-active-border-success
          mt-3
          px-8
        "
        role="tablist"
      >
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="0"
            class="nav-link active"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Messages
          </a>
        </li>
        <li class="nav-item" v-if="jobSeeker">
          <a
            v-on:click="setActiveTab"
            data-tab="1"
            class="nav-link"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            New Jobs
          </a>
        </li>
        <li class="nav-item" v-if="recruiter">
          <a
            v-on:click="setActiveTab"
            data-tab="1"
            class="nav-link"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Job Applications
          </a>
        </li>
      </ul>
    </div>

    <b-tabs class="hide-tabs" v-model="tabIndex">
      <b-tab active>
        <perfect-scrollbar
          class="navi navi-hover scroll my-4"
          style="max-height: 40vh; position: relative"
        >
          <template v-for="(item, i) in messages">
            <a href="#" class="navi-item" v-bind:key="i">
              <div class="navi-link">
                <div class="navi-text">
                  <div class="font-weight-bold">
                    {{ item.sender.name }}
                  </div>
                  <div class="text-muted" v-html="item.message"></div>
                </div>
                <div>
                  <p>{{ moment(item.created_at).format("LLL") }}</p>
                </div>
              </div>
            </a>
          </template>
        </perfect-scrollbar>
      </b-tab>

      <b-tab class="p-8" v-if="jobSeeker">
        <perfect-scrollbar
          class="scroll pr-7 mr-n7"
          style="max-height: 40vh; position: relative"
        >
          <template v-for="(item, i) in data">
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-6" v-bind:key="i">
              <!--begin::Symbol-->
              <div
                class="symbol symbol-40 mr-5"
                v-bind:class="`symbol-light-primary`"
              >
                <span class="symbol-label">
                  <img
                    style="width: 25px"
                    :src="mediaUrl + item.recruiter.profile_pic"
                    class="h-15 align-self-center"
                    alt=""
                  />
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link
                  :to="
                    '/employer/' +
                    item.recruiter.company_name
                      .toLowerCase()
                      .replace(/ /g, '-')
                      .replace(/[^\w-]+/g, '')
                  "
                  target="_blank"
                >
                  <span class="font-weight-bolder">{{
                    item.recruiter.company_name
                  }}</span>
                </router-link>
                <span class="text-muted">
                  <router-link
                    :to="
                      '/jobs-careers/pakistan/' +
                      item.job.location_city
                        .map((v) => v.toLowerCase())
                        .join('-') +
                      '/' +
                      item.job.job_title
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, '') +
                      '/' +
                      item.id
                    "
                    target="_blank"
                  >
                    {{ item.job.job_title }}
                  </router-link>
                </span>
              </div>

              <p class="text-muted" style="margin-left: 10%">
                {{ item.job.salary_min }} - {{ item.job.salary_max }}
              </p>

              <a
                class="btn btn-icon btn-light btn-sm"
                style="margin-right: 3%"
                @click.prevent="viewJob(item.job, item.id)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    src="media/svg/icons/General/Visible.svg"
                  ></inline-svg>
                  <!--end::Svg Icon-->
                </span>
              </a>
              <a
                @click.prevent="read(item.id)"
                class="btn btn-icon btn-light btn-sm"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    src="media/svg/icons/General/Trash.svg"
                  ></inline-svg>
                  <!--end::Svg Icon-->
                </span>
              </a>
              <!--end::Text-->
            </div>
            <!--end::Item-->
          </template>
        </perfect-scrollbar>
      </b-tab>

      <b-tab class="p-8" v-if="recruiter">
        <perfect-scrollbar
          class="scroll pr-7 mr-n7"
          style="max-height: 40vh; position: relative"
        >
          <!-- {{ jobApplications }} -->
          <template v-for="(item, i) in jobApplications">
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-6" v-bind:key="i">
              <!--begin::Symbol-->
              <div
                class="symbol symbol-40 mr-5"
                v-bind:class="`symbol-light-primary`"
              >
                <span class="symbol-label">
                  <img
                    style="width: 25px"
                    :src="mediaUrl + item.job_seeker_profile.profile_pic"
                    class="h-75 align-self-end"
                    alt=""
                  />
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <span class="font-weight-bolder">
                  <!-- {{ item.job_seeker.name }} -->
                </span>
                <span class="text-muted">
                  {{ item.job_seeker_profile.profession }}
                </span>
              </div>

              <p class="text-muted" style="margin-left: 10%">
                {{ item.job.salary_min }} - {{ item.job.salary_max }}
              </p>

              <router-link
                :to="'/recruiter/job-application-detail/' + item.id"
                class="btn btn-light-success font-weight-bolder font-size-sm"
              >
                View application
              </router-link>
              <!--end::Text-->
            </div>
            <!--end::Item-->
          </template>
        </perfect-scrollbar>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import moment from "moment";
export default {
  name: "KTDropdownNotification",
  data() {
    return {
      jobSeeker:
        JSON.parse(localStorage.getItem("user")).role == "Job Seeker"
          ? true
          : false,
      recruiter:
        JSON.parse(localStorage.getItem("user")).role == "Direct Employer"
          ? true
          : false,
      tabIndex: 0,
      data: JSON.parse(localStorage.getItem("followupJobs")),
      messages: JSON.parse(localStorage.getItem("messages")),
      jobApplications: JSON.parse(localStorage.getItem("jobApplications")),
      mediaUrl: MEDIA_URL,
      token: `Bearer ${localStorage.getItem("token")}`,
    };
  },
  methods: {
    moment: function () {
      return moment();
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    viewJob(item, id) {
      this.read(id);
      this.$router.push({
        path:
          "/jobs-careers/pakistan/" +
          item.location_city.map((v) => v.toLowerCase()).join("-") +
          "/" +
          item.job_title
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "") +
          "/" +
          item.id,
        params: {
          search_text: this.search_text,
          selected_cities: [{ id: 1, value: item }],
        },
      });
    },
    read(id) {
      axios
        .get(`${API_URL}/read-company-watch-list-alert/${id}`)
        .then((res) => {
          this.fetch();
        });
    },
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    },
  },
};
</script>

<template>
  <div class="topbar-item">
    <div class="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_panel_toggle">
      <span class="svg-icon svg-icon-xl svg-icon-primary">
        <!-- <inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg" /> -->
        <img
          src="\images/nav_icon/box1.svg"
          alt=""
          height="20px"
          width="20px"
        />
      </span>
    </div>

    <!-- begin::Quick Panel -->
    <div
      id="kt_quick_panel"
      ref="kt_quick_panel"
      class="offcanvas offcanvas-right pt-5 pb-10"
      style="overflow: hidden"
    >
      <!--begin::Header-->
      <div
        class="
          offcanvas-header offcanvas-header-navs
          d-flex
          align-items-center
          justify-content-between
          mb-5
        "
      >
        <ul
          class="
            nav
            nav-bold
            nav-tabs
            nav-tabs-line
            nav-tabs-line-3x
            nav-tabs-primary
            flex-grow-1
            px-10
          "
          role="tablist"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="true"
            >
              New jobs
            </a>
          </li>
          <!-- <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="true"
            >
              Applied for
            </a>
          </li> -->
          <!-- <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="true"
            >
              Invited for
            </a>
          </li> -->
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="1"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="false"
            >
              Notifications
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="2"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="false"
            >
              Settings
            </a>
          </li>
        </ul>
        <div class="offcanvas-close mt-n1 pr-5">
          <a
            href="#"
            class="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_panel_close"
          >
            <i class="ki ki-close icon-xs text-muted"></i>
          </a>
        </div>
      </div>
      <!--end::Header-->

      <div class="offcanvas-content px-10">
        <div class="tab-content">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div class="scroll pr-7 mr-n7" id="kt_quick_panel_logs">
                <div class="mb-15">
                  <h5 class="font-weight-bold mb-5">Latest jobs</h5>
                  <template v-for="(item, i) in spotlightData">
                    <!--begin::Item -->
                    <div
                      class="d-flex align-items-center rounded p-5 gutter-b"
                      v-bind:class="`bg-light-${item.type}`"
                      v-bind:key="i"
                    >
                      <span
                        class="svg-icon mr-5"
                        v-bind:class="`svg-icon-${item.type}`"
                      >
                        <span class="svg-icon svg-icon-lg">
                          <!--begin::Svg Icon-->
                          <img
                            :src="mediaUrl + item.recruiter.profile_pic"
                            width="50"
                          />
                          <!--end::Svg Icon-->
                        </span>
                      </span>
                      <div class="d-flex flex-column flex-grow-1 mr-2">
                        <router-link
                          :to="
                            '/jobs-careers/pakistan/' +
                            item.location_city
                              .map((v) => v.toLowerCase())
                              .join('-') +
                            '/' +
                            item.job_title
                              .toLowerCase()
                              .replace(/ /g, '-')
                              .replace(/[^\w-]+/g, '') +
                            '/' +
                            item.id
                          "
                          class="
                            font-weight-normal
                            text-dark-75 text-hover-primary
                            font-size-lg
                            mb-1
                          "
                        >
                          {{ item.job_title }}
                        </router-link>
                        <router-link
                          :to="
                            '/employer/' +
                            item.recruiter.company_name
                              .toLowerCase()
                              .replace(/ /g, '-')
                              .replace(/[^\w-]+/g, '')
                          "
                          target="_blank"
                        >
                          <span class="text-muted font-size-sm">
                            {{ item.recruiter.company_name }}
                          </span>
                        </router-link>
                      </div>
                      <router-link
                        :to="
                          '/jobs-careers/pakistan/' +
                          item.location_city
                            .map((v) => v.toLowerCase())
                            .join('-') +
                          '/' +
                          item.job_title
                            .toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '')
                        "
                        target="_blank"
                      >
                        View
                      </router-link>
                    </div>
                    <!--end::Item -->
                  </template>
                </div>

                <!-- <div class="mb-15">
                  <h5 class="font-weight-bold mb-5">Notifications</h5>
                  <template v-for="(item, i) in list2">
                    
                    <div
                      class="d-flex align-items-center rounded p-5 mb-5"
                      v-bind:key="i"
                      v-bind:class="`bg-light-${item.type}`"
                    >
                      <span
                        class="svg-icon mr-5"
                        v-bind:class="`svg-icon-${item.type}`"
                      >
                        <inline-svg
                          :src="item.svg"
                          class="h-50 align-self-center"
                        />
                      </span>

                      <div class="d-flex flex-column flex-grow-1 mr-2">
                        <a
                          href="#"
                          class="
                            font-weight-normal
                            text-dark-75 text-hover-primary
                            font-size-lg
                            mb-1
                          "
                        >
                          {{ item.title }}
                        </a>
                        <span class="text-muted font-size-sm">
                          {{ item.desc }}
                        </span>
                      </div>

                      <span
                        class="font-weight-bolder py-1 font-size-lg"
                        v-bind:class="`text-${item.type}`"
                      >
                        {{ item.alt }}
                      </span>
                    </div>
                   
                  </template>
                </div> -->
              </div>
            </b-tab>

            <b-tab>
              <div class="scroll pr-7 mr-n7" id="kt_quick_panel_notifications">
                <!--begin::Nav-->
                <div class="navi navi-icon-circle navi-spacer-x-0">
                  <template v-for="(item, i) in list3">
                    <!--begin::Item-->
                    <a
                      href="/job-seeker/profile"
                      class="navi-item"
                      v-bind:key="i"
                    >
                      <div class="navi-link rounded">
                        <div class="symbol symbol-50 mr-3">
                          <div class="symbol-label">
                            <i class="icon-lg" v-bind:class="item.icon" />
                          </div>
                        </div>
                        <div class="navi-text">
                          <div class="font-weight-bold font-size-lg">
                            {{ item.title }}
                          </div>
                          <div class="text-muted">
                            {{ item.desc }}
                          </div>
                        </div>
                      </div>
                    </a>
                    <!--end::Item-->
                  </template>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <div class="scroll pr-7 mr-n7" id="kt_quick_panel_settings">
                <form class="form">
                  <!--begin::Section-->
                  <div>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                        >Looking for job:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-success switch-sm">
                          <label>
                            <input type="checkbox" checked="checked" name="" />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <!-- <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                        >Enable Case Tracking:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-success switch-sm">
                          <label>
                            <input
                              type="checkbox"
                              name="quick_panel_notifications_2"
                            />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                        >Support Portal:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-success switch-sm">
                          <label>
                            <input type="checkbox" checked="checked" name="" />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div> -->
                  </div>
                  <!--end::Section-->

                  <div class="separator separator-dashed my-6"></div>

                  <!--begin::Section-->
                  <!-- <div class="pt-2">
                    <h5 class="font-weight-bold mb-3">Reports</h5>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                        >Generate Reports:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-sm switch-danger">
                          <label>
                            <input type="checkbox" checked="checked" name="" />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                        >Enable Report Export:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-sm switch-danger">
                          <label>
                            <input type="checkbox" name="" />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                        >Allow Data Collection:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-sm switch-danger">
                          <label>
                            <input type="checkbox" checked="checked" name="" />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div> -->
                  <!--end::Section-->

                  <div class="separator separator-dashed my-6"></div>

                  <!--begin::Section-->
                  <!-- <div class="pt-2">
                    <h5 class="font-weight-bold mb-3">Memebers</h5>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                        >Enable Member singup:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-sm switch-primary">
                          <label>
                            <input type="checkbox" checked="checked" name="" />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                        >Allow User Feedbacks:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-sm switch-primary">
                          <label>
                            <input type="checkbox" name="" />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="form-group mb-0 row">
                      <label class="col-8 col-form-label"
                        >Enable Customer Portal:</label
                      >
                      <div class="col-4 text-right">
                        <span class="switch switch-sm switch-primary">
                          <label>
                            <input type="checkbox" checked="checked" name="" />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div> -->
                  <!--end::Section-->
                </form>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <!-- end::Quick Panel -->
  </div>
</template>

<script>
import KTLayoutQuickPanel from "@/assets/js/layout/extended/quick-panel.js";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { API_URL, MEDIA_URL } from "@/core/api";
import axios from "axios";
export default {
  name: "KTQuickPanel",
  data() {
    return {
      role: JSON.parse(localStorage.getItem("user")).role,
      mediaUrl: MEDIA_URL,
      API_URL: API_URL,
      spotlightData: [],
      tabIndex: 0,
      list1: [
        {
          title: "Top Authors",
          desc: "Most Successful Fellas",
          alt: "+82$",
          svg: "media/svg/misc/006-plurk.svg",
        },
        {
          title: "Popular Authors",
          desc: "Most Successful Fellas",
          alt: "+280$",
          svg: "media/svg/misc/006-plurk.svg",
        },
        {
          title: "New Users",
          desc: "Most Successful Fellas",
          alt: "+4500$",
          svg: "media/svg/misc/015-telegram.svg",
        },
        {
          title: "Active Customers",
          desc: "Most Successful Fellas",
          alt: "+4500$",
          svg: "media/svg/misc/003-puzzle.svg",
        },
      ],
      list2: [
        {
          title: "Another purpose persuade",
          desc: "Due in 2 Days",
          alt: "+28%",
          svg: "media/svg/icons/Home/Library.svg",
          type: "warning",
        },
        {
          title: "Would be to people",
          desc: "Due in 2 Days",
          alt: "+50%",
          svg: "media/svg/icons/Communication/Write.svg",
          type: "success",
        },
        {
          title: "Purpose would be to persuade",
          desc: "Due in 2 Days",
          alt: "-27%",
          svg: "media/svg/icons/Communication/Group-chat.svg",
          type: "danger",
        },
        {
          title: "The best product",
          desc: "Due in 2 Days",
          alt: "+8%",
          svg: "media/svg/icons/General/Attachment2.svg",
          type: "info",
        },
      ],
      list3: [
        {
          title: "Welcome to job4u.pk network",
          desc: "Please complete your employability profile",
          icon: "flaticon-bell text-success",
        },
      ],
    };
  },
  mounted() {
    // Init Quick Offcanvas Panel
    KTLayoutQuickPanel.init(this.$refs["kt_quick_panel"]);
    axios.get(`${this.API_URL}/get-spotlight-jobs`).then((response) => {
      this.spotlightData = response.data;
    });
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
  },
};
</script>
